<template>
  <div class="rn_my_box_12">
    <div class="average">
      <div class="left">
        <b>{{ reviewer_nick }}</b
        >님의
      </div>
      <ul class="right">
        <li>
          <div>리뷰 만족도</div>
          <div>평균</div>
          <div>{{ satisfactionCp }}</div>
        </li>
        <li>
          <div>구매 유인도</div>
          <div>평균</div>
          <div>{{ incentiveCp }}</div>
        </li>
        <li>
          <div>제품 평가지수</div>
          <div>평균</div>
          <div>{{ evaluationCp }}</div>
        </li>
      </ul>
    </div>
    <div class="list">
      <div class="box">
        <div class="top">
          <div class="left">
            <b>리뷰어 신뢰도 지수</b>(램 플랫폼 안 소비자 평가)
          </div>
          <div class="right">평균 {{ trustScoreCp }}</div>
        </div>
        <ul>
          <li v-for="list in listArray" :key="list.main_menu_seq">
            {{ list.title }} {{ rammFloat(list.trust_score) }}
          </li>
        </ul>
      </div>
      <!-- <div class="box">
          <div class="top">
            <div class="left"><b>리뷰어 신뢰도 지수</b>(제휴사 평가)</div>
            <div class="right">평균 3.7</div>
          </div>
          <ul>
            <li>상품 721</li>
            <li>책/음반 235</li>
            <li>뉴스 2,543</li>
            <li>헬스 12</li>
            <li>여행 37</li>
            <li>스포츠 123</li>
          </ul>
        </div> -->
      <div class="box">
        <div class="top">
          <div class="left"><b>리뷰어 리뷰수</b></div>
          <div class="right">총 {{ reviewCntCp }}개</div>
        </div>
        <ul>
          <li v-for="list in listArray" :key="list.main_menu_seq">
            {{ list.title }} {{ list.cnt }}
          </li>
        </ul>
      </div>
      <div class="box">
        <div class="top">
          <div class="left"><b>리뷰를 통한 구매건수</b></div>
          <div class="right">총 {{ saleCntCp }}건</div>
        </div>
        <ul>
          <li v-for="list in listArray" :key="list.main_menu_seq">
            {{ list.title }} {{ list.sale_cnt }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    reviewer_nick: {
      type: String,
      default: "",
      required: true,
    },
    satisfaction: {
      type: Number,
      default: 0,
      required: true,
    },
    incentive: {
      type: Number,
      default: 0,
      required: true,
    },
    evaluation: {
      type: Number,
      default: 0,
      required: true,
    },
    trustScore: {
      type: Number,
      default: 0,
      required: true,
    },
    listArray: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  computed: {
    trustScoreCp() {
      let score = 0;
      if (this.listArray.length > 0) {
        this.listArray.map((list) => {
          score += Number(list.trust_score);
        });
        score = score / this.listArray.length;
      }
      return this.$rammFloat(score);
    },
    satisfactionCp() {
      if (Number.isInteger(this.satisfaction)) {
        return `${this.satisfaction}.0`;
      }
      return this.satisfaction;
    },
    incentiveCp() {
      if (Number.isInteger(this.incentive)) {
        return `${this.incentive}.0`;
      }
      return this.incentive;
    },
    evaluationCp() {
      if (Number.isInteger(this.evaluation)) {
        return `${this.evaluation}.0`;
      }
      return this.evaluation;
    },
    reviewCntCp() {
      let cnt = 0;
      this.listArray.map((list) => {
        cnt += Number(list.cnt);
      });
      return this.$won(cnt);
    },
    saleCntCp() {
      let cnt = 0;
      this.listArray.map((list) => {
        cnt += Number(list.sale_cnt);
      });
      return this.$won(cnt);
    },
  },
  methods: {
    rammFloat(str) {
      return this.$rammFloat(str);
    },
  },
};
</script>

<style></style>
