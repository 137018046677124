<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_2">
      내 리뷰 통계<router-link to="/mypage/my_info"
        ><img src="@/assets/images/sub/back_ico.png" alt=""
      /></router-link>
    </div>
    <!---->
    <div class="rn_my_empty_20"></div>
    <!---->
    <div class="info">· [내 리뷰 통계]는 지난 1년간의 데이터 통계 입니다.</div>
    <div class="rn_my_tab_3">
      <div class="on"><a href="#">일반</a></div>
      <div><a href="#">&nbsp;</a></div>
    </div>
    <!---->
    <div class="rn_my_empty_15"></div>
    <!---->
    <ReviewStatisticsNormal
      :reviewer_nick="myInfo.reviewer_nick"
      :satisfaction="satisfaction"
      :incentive="incentive"
      :evaluation="evaluation"
      :trustScore="trustScore"
      :listArray="listArray"
    />
    <!---->
  </div>
</template>

<script>
import { mapState } from "vuex";
import ReviewStatisticsNormal from "@/components/review/staticstics/ReviewStatisticsNormal";
export default {
  created() {
    //초기화;
    this.$store.dispatch("common/setFooter", {
      mypage: true,
    });
    this.getReviewEvaluation();
  },
  computed: {
    ...mapState("mypage", ["myInfo"]),
    ...mapState("review", [
      "satisfaction",
      "incentive",
      "evaluation",
      "trustScore",
      "listArray",
    ]),
  },
  methods: {
    async getReviewEvaluation() {
      await this.$store.dispatch("review/getReviewEvaluation");
    },
  },
  components: {
    ReviewStatisticsNormal,
  },
};
</script>

<style lang="scss" scoped>
.info {
  font-size: 12px;
  padding: 10px 5px;
}
</style>
